import { graphHooks } from '@tunasong/graph-lib/react'
import type { TunaEditor, TunaPlugin } from '@tunasong/plugin-lib'
import type { CoreElement, Entity } from '@tunasong/schemas'
import { isCoreElement, isEntity } from '@tunasong/schemas'
import { styled } from '@mui/material/styles'
import cn from 'classnames'
import { useCallback, useEffect } from 'react'
import invariant from 'tiny-invariant'
import { useCreateEditor } from '../hooks/create-editor.js'
import type { ElementEditorProps } from './element-editor.js'
import { ElementEditor } from './element-editor.js'

export interface EntityEditorProps<T extends Entity = Entity>
  extends Omit<ElementEditorProps, 'editor' | 'onChange' | 'element'> {
  className?: string
  element: T
  gutter?: boolean
  plugins: TunaPlugin[]
  /** Create the entity from element on change. */
  upsert?: boolean
  onEditor?(editor: TunaEditor): void
}

/** Editor that handles Entities and will dispatch changes to Redux */
const EditorWithGutter = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 4, 0, 4),
}))

/** EntityEditor requires a persisted entity and will update the Redux state */

export function EntityEditor<T extends Entity = Entity>(props: EntityEditorProps<T>) {
  const { className, plugins = null, element, upsert = false, gutter = false, onEditor, ...restProps } = props

  const editor = useCreateEditor(element, plugins)

  useEffect(() => {
    if (editor && onEditor) {
      onEditor(editor)
    }
  }, [editor, onEditor])

  const update = graphHooks.useEntityUpdate({ debounceDelay: 1 * 1000, upsert })
  const handleChange = useCallback(
    async (e: CoreElement) => {
      invariant(isEntity(e) && e.id, `EntityEditor for non-entity: ${JSON.stringify(e)}`)

      update(e.id, e)
    },
    [update]
  )

  if (!isCoreElement(element)) {
    return null
  }

  return editor ? (
    <ElementEditor
      className={cn(className, { [EditorWithGutter.toString()]: gutter })}
      {...restProps}
      gutter={gutter}
      element={element}
      editor={editor}
      onChange={handleChange}
    />
  ) : null
}

export default EntityEditor
