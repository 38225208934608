import { logger } from '@tunasong/models'
import { useEditorMessage } from '@tunasong/plugin-lib'
import type { EditorEvent, EditorEventType, TunaEditor } from '@tunasong/plugin-lib'
import { Dialog, getSelectionAnchorEl } from '@tunasong/ui-lib'
import type { VirtualElement } from '@tunasong/ui-lib'
import { useCallback, useMemo, useState } from 'react'
import { Location, Path } from 'slate'
import { ReactEditor } from 'slate-react'
import EditorPopup from './editor-popup.js'
import type { EditorPopupProps } from './editor-popup.js'
import { getHTMLElementAtPath } from '../util/html-element-at-path.js'

interface CreateEditorPopup {
  /** Event to trigger the popup */
  showType: EditorEventType
  /** Type of popup. @default popper */
  type?: 'mui' | 'popper'
  anchorEl?: VirtualElement | HTMLElement | null
  /**
   * How to determine the anchorEl
   * - editorselection: use the current editor selection
   * - editorevent: use the provided editor selection from the event
   * - windowselection: use the current window selection
   * - dynamic: use the provided anchorEl or the current window selection
   */
  anchorElMode?: 'editorselection' | 'editorevent' | 'windowselection' | 'dynamic'
  title?: string
  /** @default hide-modal */
  hideType?: EditorEventType
  editor: TunaEditor
  /** Don't listen to events */
  disabled?: boolean
  /** Add a FocusLock */
  focusLock?: boolean
  onOpen?: (ev: EditorEvent) => void
  onClose?: () => void
}
export const useEditorPopup = ({
  editor,
  showType,
  hideType = 'hide-modal',
  anchorEl: providedAnchorEl,
  anchorElMode = 'dynamic',
  disabled,
  focusLock = true,
  type = 'popper',
  title = '',
  onOpen,
  onClose,
}: CreateEditorPopup) => {
  const [open, setOpen] = useState(false)

  if (anchorElMode !== 'dynamic' && providedAnchorEl) {
    throw new Error(`anchorEl requires anchorElMode='dynamic'`)
  }

  const [anchorEl, setAnchorEl] = useState<VirtualElement | HTMLElement | null | undefined>(providedAnchorEl)

  const [path, setPath] = useState<Path | null>(null)
  const [selection, setSelection] = useState<Location>()

  const handleOpen = useCallback(
    (ev: EditorEvent) => {
      setPath(ev.detail.nodeEntry[1])
      setSelection(ev.detail.selection)

      if (!providedAnchorEl && (anchorElMode === 'windowselection' || anchorElMode === 'dynamic')) {
        setAnchorEl(getSelectionAnchorEl())
      }
      if (!providedAnchorEl && anchorElMode === 'editorselection') {
        setAnchorEl(getHTMLElementAtPath(editor, editor.selection?.anchor.path))
      }
      if (!providedAnchorEl && anchorElMode === 'editorevent') {
        setAnchorEl(getHTMLElementAtPath(editor, ev.detail.nodeEntry[1]))
      }

      if (onOpen) {
        onOpen(ev)
      }
      setOpen(true)
    },
    [anchorElMode, editor, onOpen, providedAnchorEl]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setPath(null)
    setSelection(undefined)
    setOpen(false)
    // we have seen that the editor is not rendered here
    if (onClose) {
      onClose()
    }
    // defer the focus to the next tick
    setTimeout(() => {
      try {
        // if we're navigating away from this page, we may get into trouble here, so we catch the error
        ReactEditor.focus(editor as unknown as ReactEditor)
        // this is required to ensure we don't lose the selection
        editor.api.onChange()
      } catch (e) {
        logger.warn(`Focus editor failed`, { e })
      }
    }, 10)
  }, [editor, onClose])

  useEditorMessage({ editor, type: showType, handler: handleOpen, disabled })
  useEditorMessage({ editor, type: hideType, handler: handleClose, disabled })

  // Use bottom for range selections, right-start for cursor
  const selectionWidth = anchorEl?.getBoundingClientRect().width ?? 0
  const placement = selectionWidth > 10 ? 'bottom' : 'right-start'

  const Popup = useMemo(
    () =>
      ({ children, ...restProps }: Partial<EditorPopupProps>) =>
        open ? (
          type === 'popper' ? (
            <EditorPopup
              sx={{ height: 300, maxWidth: 'md' }}
              open={open}
              editor={editor}
              anchorEl={anchorEl}
              onClose={handleClose}
              disablePortal={false}
              focusLock={focusLock}
              placement={placement}
              {...restProps}
            >
              {children}
            </EditorPopup>
          ) : (
            <Dialog
              title={title}
              open={open}
              onClose={handleClose}
              maxWidth="md"
              fullWidth
              disableRestoreFocus
              disableEnforceFocus
            >
              {children}
            </Dialog>
          )
        ) : null,
    [anchorEl, editor, focusLock, handleClose, open, placement, title, type]
  )

  return {
    anchorEl,
    path,
    selection,
    closePopup: handleClose,
    /** Use these as props to EditorPopup */
    Popup,
  }
}

export default useEditorPopup
