import { capitalize, Typography } from '@mui/material'
import { useEditor, useEditorMessage } from '@tunasong/plugin-lib'
import type { CoreElement } from '@tunasong/schemas'
import { PulseProgress, useCmdHotkey } from '@tunasong/ui-lib'
import type { FC } from 'react'
import React, { useCallback } from 'react'

export interface EmptyBlockProps {
  className?: string
  element: CoreElement
  name: string
  Icon: FC
  loading?: boolean
}

export const EmptyBlock: FC<EmptyBlockProps> = props => {
  const { className, name, Icon, element, loading = false } = props
  const editor = useEditor()
  const propertiesCmd = useCmdHotkey('Block: Properties')
  const showProperties = useEditorMessage({ editor, type: 'show-block-properties' })

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = useCallback(
    ev => {
      showProperties(element, ev.currentTarget)
    },
    [element, showProperties]
  )

  return (
    <Typography
      sx={{
        color: theme => theme.vars.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      className={className}
      contentEditable={false}
      variant="caption"
      onClick={handleClick}
    >
      <Icon />
      {loading ? (
        <PulseProgress />
      ) : (
        <>
          {capitalize(name)} block is not configured.
          {propertiesCmd?.label} or click on this block to configure.
        </>
      )}
    </Typography>
  )
}
