/** Extract entityLinks in the specified range, and return the entities */
import { isCollabEntity } from '@tunasong/models'
import { usePlugins, useYDoc } from '@tunasong/plugin-lib'
import type { Entity, EntityLink } from '@tunasong/schemas'
import { isEntityLink } from '@tunasong/schemas'
import { EntityQueries } from '../editor/queries/entities.js'
import { RangeQueries } from '../editor/queries/range.js'
import { useCreateEditor } from './create-editor.js'

/** A toolbox for interacting with a page */
export const usePageTools = (entity: Entity | null = null) => {
  const allPlugins = usePlugins('all')

  const { element: decodedPage = entity } = useYDoc(isCollabEntity(entity) ? (entity ?? undefined) : undefined)
  const editor = useCreateEditor(decodedPage, allPlugins)

  /** Extract the links, get the linked entities by ID */
  const getEntityLinks = (at = editor ? RangeQueries.all(editor) : undefined) => {
    if (!editor) {
      return []
    }
    return EntityQueries.find(editor, isEntityLink, at).map(e => e[0]) as unknown as EntityLink[]
  }
  /** Return the entity IDs of the linked entities */
  const getEntityLinksIds = (at = editor ? RangeQueries.all(editor) : undefined) =>
    getEntityLinks(at).map(e => e.ref.id)

  return { editor, page: decodedPage, getEntityLinks, getEntityLinksIds }
}
