// @refresh reset
import type { BoxProps } from '@mui/material'
import { Box, useTheme } from '@mui/material'
import { ensureAllNodesHaveChildren } from '@tunasong/models'
import type { MusicContextType } from '@tunasong/music-lib'
import { buildMusicContext } from '@tunasong/music-ui'
import type { EditorEvent, TunaEditor } from '@tunasong/plugin-lib'
import { RenderPlugins, TunaEditorContext, getEditorContainerId, useEditorMessage } from '@tunasong/plugin-lib'
import { useMusicContext } from '@tunasong/redux'
import type { CoreElement, TunaDecendant } from '@tunasong/schemas'
import { BrowserCapabilities, useDelayedInvoke } from '@tunasong/ui-lib'
import { Plate, PlateContent } from '@udecode/plate-core/react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Node } from 'slate'
import invariant from 'tiny-invariant'
import { RenderLeaf } from './render-leaf.js'

export interface ElementEditorProps<T extends CoreElement = CoreElement> extends Omit<BoxProps, 'onChange'> {
  className?: string
  editor: TunaEditor
  element: T
  readOnly?: boolean
  autoFocus?: boolean

  /**
   * Placeholder text to show when the element is empty
   */
  placeholder?: string
  /** Use gutter. Required for hover-over icon to work */
  gutter?: boolean
  /** ready to render. Set to `false` if rendering should be postponed. @default true */
  ready?: boolean

  initialValue?: TunaDecendant[]

  /** onChange must ensure the updated element is set in element */
  onChange?(element: T): void
  /** Editor is considering the document complete - e.g., for a single-line editor */
  onComplete?(event: EditorEvent): void
  onContext?(context: MusicContextType): void
}

export const ElementEditor = <T extends CoreElement = CoreElement>(props: ElementEditorProps<T>) => {
  const {
    className,
    children,
    editor,
    element,
    initialValue,
    autoFocus,
    gutter = true,
    readOnly = false,
    ready = true,
    placeholder,
    onChange,
    onComplete,
    onContext,

    ...restProps
  } = props
  /** Messages that are handled by the plugin(s) */
  const showContextMenu = useEditorMessage({ editor, type: 'show-context-menu' })
  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      const target = event.currentTarget instanceof HTMLElement ? event.currentTarget : null
      showContextMenu(element, target)
    },
    [element, showContextMenu]
  )

  /** Defer complete to allow onChange to finish */
  useEditorMessage({ editor, type: 'complete', handler: onComplete })

  /** Update context when editor and/element changes */
  const [, setContext] = useMusicContext({ editorId: editor.id, selector: state => state })
  const [defaultContext] = useMusicContext({ editorId: 'default', selector: state => state })

  /** Initial context update on load */
  const initialized = useRef(false)

  useEffect(() => {
    if (!(editor && element && !initialized.current)) {
      return
    }
    initialized.current = true

    setContext(buildMusicContext(editor, element, defaultContext))

    editor.setProperty('rootElement', element)
    invariant(editor.rootElement === element)

    editor.setProperty('placeholder', placeholder)
    invariant(editor.placeholder === placeholder)
  }, [defaultContext, editor, element, placeholder, setContext])

  /** Update the context after 250ms of no changes since that is potentially expensive */
  const delayedContext = useDelayedInvoke(250)

  const handleChange = useCallback(
    ({ editor, value: nodes }: { editor: TunaEditor; value: Node[] }) => {
      // Context depends on the selection
      delayedContext(() => {
        const editContext = buildMusicContext(editor, element)
        setContext(editContext)
        if (onContext) {
          onContext(editContext)
        }
      })

      /** if only selection has changed, do not call onChange */
      if (editor.operations.length > 0 && editor.operations.every(op => op.type === 'set_selection')) {
        return
      }

      if (!onChange) {
        return
      }
      const newElement: T = {
        ...element,
        children: nodes as TunaDecendant[],
      }

      onChange(newElement)
    },
    [delayedContext, element, onChange, onContext, setContext]
  )

  // this seems unnecessary if we do initial normalization
  const sanitizedValue = useMemo(() => {
    const val = initialValue ?? element?.children ?? null
    return val ? val.map(ensureAllNodesHaveChildren) : null
  }, [element?.children, initialValue]) as CoreElement[]
  const theme = useTheme()

  if (!ready || !sanitizedValue || (Array.isArray(sanitizedValue) && sanitizedValue.length === 0)) {
    return null
  }

  return (
    <Box
      id={getEditorContainerId(editor)}
      className={className}
      {...restProps}
      sx={{
        display: 'flex',
        flex: 1,
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      <RenderPlugins editor={editor} type="root" />
      <TunaEditorContext.Provider value={editor}>
        <RenderPlugins editor={editor} type="top" />

        <Plate
          key={editor.id}
          editor={editor as never}
          onValueChange={handleChange}
          renderLeaf={RenderLeaf}
          // data-autofocus is telling FocusLock to zero in on this
          data-autofocus
        >
          <PlateContent
            style={{
              display: 'flex',
              outline: 'none',
              padding: gutter ? theme.spacing(0, 3, 0, 3) : 0,
              flexDirection: 'column',
              flex: '1 1 auto',
              cursor: 'auto',

              position: 'relative',
            }}
            autoFocus={autoFocus}
            spellCheck={false}
            onContextMenu={onContextMenu}
            readOnly={readOnly || !BrowserCapabilities.supportsEdit}
          >
            {children}
          </PlateContent>
        </Plate>
      </TunaEditorContext.Provider>
    </Box>
  )
}
