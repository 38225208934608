import type { PluginConfigSet } from '@tunasong/plugin-lib'
import { usePlugins } from '@tunasong/plugin-lib'
import React from 'react'
import type { EditorContextProps } from './editors-context.js'

/**
 * Lazy load of plugins.
 */
export const LazyEditor = <T extends EditorContextProps, S extends Omit<T, 'plugins'> = Omit<T, 'plugins'>>(
  Editor: React.FC<T>,
  pluginCollection: PluginConfigSet
): React.FC<S> =>
  function ResolvedEditor(props: S) {
    const p = usePlugins(pluginCollection)
    /** @note Typescript does not infer that Omit<T, 'plugins'> & Pick<T, 'plugins'> are the same type - https://github.com/microsoft/TypeScript/issues/35858 */
    return p ? <Editor plugins={p} {...(props as unknown as T)} /> : null
  }
