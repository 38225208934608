import type { BoxProps } from '@mui/material'
import { Box, ButtonBase, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Menu } from '@tunasong/icons'
import type { RenderElementPropsExt } from '@tunasong/plugin-lib'
import { renderBlockDecorations, useEditor, useEditorMessage } from '@tunasong/plugin-lib'
import type { CoreElement } from '@tunasong/schemas'
import { useMergeRefs } from '@tunasong/ui-lib'
import type { FC, RefObject } from 'react'
import React, { useCallback, useState } from 'react'
import type { DragElementWrapper, DragSourceOptions } from 'react-dnd'
import { useReadOnly, useSelected } from 'slate-react'
import { BlockMenu } from './block-menu.js'

const GutterLeft = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(-2),
  transform: 'translateX(-100%)',
  display: 'flex',
  height: '100%',
  width: theme.spacing(2),
  opacity: 0.2,
  transition: 'all 0.3s',
  ':hover': {
    opacity: 1,
  },
  borderRadius: 0,
}))

const BlockToolbar = styled('div')({
  width: 18,
  height: 18,
  marginRight: 4,
  pointerEvents: 'auto',
  position: 'relative',
})

interface DropLineProps {
  position?: 'top' | 'bottom'
}

const DropLine = styled('div', {
  shouldForwardProp: prop => prop !== 'position',
})<DropLineProps>(({ theme, position }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  height: 2,
  opacity: 1,
  background: theme.vars.palette.secondary.main,
  ...(position === 'top' && {
    top: -1,
  }),
  ...(position === 'bottom' && {
    bottom: -1,
  }),
}))

export interface ConfigureBlock {
  prop: string
  validate: (val: unknown) => boolean
  type?: 'text'

  title?: string
}

export interface CoreBlockProps extends RenderElementPropsExt, Omit<BoxProps, 'component' | 'children' | 'border'> {
  className?: string
  component?: 'div' | 'span'
  element: CoreElement
  focus?: boolean
  /** Right border on focus */
  border?: boolean
  menu?: boolean

  /** Internal */
  dragRef?: DragElementWrapper<DragSourceOptions>
  blockRef?: RefObject<HTMLDivElement | null>
  dropLine?: 'bottom' | 'left' | 'right' | 'top' | ''
  isDragOver?: boolean
}

/** A Block. Extends Box, so all Box */
export const CoreBlock: FC<CoreBlockProps> = props => {
  const {
    className,
    component = 'div',
    focus = true,
    menu = props.menu ?? false,
    border = true,
    attributes,
    element,
    children,
    /** DnD props */
    blockRef,
    dragRef,
    dropLine,
    isDragOver,
    sx,
    ...restProps
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  // Timestamp when it was clicked away
  const [clickedAway, setClickedAway] = useState(0)
  const blockSelected = useSelected()

  const editor = useEditor()
  const readOnly = useReadOnly()
  const { type } = element

  /** We use state here since we want to re-render if showHandle changes */
  const multiRootRef = useMergeRefs(attributes.ref, blockRef)

  const showProperties = useEditorMessage({ editor, type: 'show-block-properties' })
  const handleShowProperties = useCallback(() => showProperties(element, anchorEl), [anchorEl, element, showProperties])

  const showComments = useEditorMessage({ editor, type: 'show-comments' })
  const handleShowComments = useCallback(() => showComments(element, anchorEl), [anchorEl, element, showComments])

  const handleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      /** If clicked away recently, we do not re-open it */
      if (anchorEl || Date.now() < clickedAway + 1000) {
        setAnchorEl(null)
        setClickedAway(0)
      } else {
        setAnchorEl(event.currentTarget)
      }
    },
    [anchorEl, clickedAway]
  )
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    /** Register that the menu has been closed */
    setClickedAway(Date.now())
  }, [])

  const blockName = `${type[0].toUpperCase()}${type.substr(1)} Block`
  const hasFocus = focus && blockSelected

  const blockDecorations = renderBlockDecorations(editor, element)

  const handleMouseDown = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      /**
       * @todo we do this because slate-plugins DND requires selection null
       * @see https://www.notion.so/Editor-8a19967621ba4683aeec388ad224d3f8?p=711bda3cf8754629994c0874e1faccde&showMoveTo=true
       */
      editor.selection = null
    },
    [editor]
  )

  const isInline = component === 'span'

  return (
    <Box
      className={className}
      {...restProps}
      sx={{
        position: 'relative',
        alignItems: 'center',
        verticalAlign: 'bottom',
        // padding: theme => theme.spacing(0, 1, 0, 1),
        // left: theme => theme.spacing(-1),
        '& ::selection': {
          backgroundColor: theme => theme.vars.palette.primary.light,
          color: theme => theme.vars.palette.primary.contrastText,
        },
        /**
         * Set new coordinate system to allow children to use position fixed relative
         * @see https://drafts.csswg.org/css-transforms/#transform-rendering
         */
        transform: 'rotate(0deg)',

        ...(isInline && {
          display: 'inline-flex',
        }),

        ...((border || isDragOver) && {
          borderRightStyle: 'solid',
          borderRightWidth: theme => theme.spacing(0.25),
          borderRightColor: theme => theme.vars.palette.grey[200],
          // ...(theme.applyStyles('dark', {
          //   borderRightColor: theme.vars.palette.background.paper,
          // }) as CSSProperties),
          '@media print': {
            border: 'none',
          },
        }),

        ...(hasFocus && {
          borderRightColor: theme => theme.vars.palette.primary.main,
        }),
        ...sx,
      }}
      {...attributes}
      ref={multiRootRef}
      component={component}
    >
      {Boolean(dropLine) && (
        <DropLine
          position={dropLine === 'bottom' ? 'bottom' : dropLine === 'top' ? 'top' : undefined}
          contentEditable={false}
        />
      )}
      {menu && !readOnly && (
        <>
          <GutterLeft tabIndex={-1} title={blockName} contentEditable={false}>
            <BlockToolbar tabIndex={-1} ref={dragRef as never}>
              <ButtonBase
                sx={{
                  top: theme => theme.spacing(-1),
                  minWidth: 18,
                  height: 18,
                  padding: 0,
                  backgroundColor: 'transparent',
                  backgroundRepeat: 'no-repeat',
                  border: 'none',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  outline: 'none',
                }}
                onClick={handleMenu}
                onMouseDown={handleMouseDown}
                aria-label="Block menu"
                contentEditable={false}
              >
                <Menu
                  sx={{
                    fontSize: 24,
                    color: theme => theme.vars.palette.text.secondary,
                  }}
                  fontSize="inherit"
                />
              </ButtonBase>
            </BlockToolbar>
          </GutterLeft>
          {anchorEl ? (
            <BlockMenu
              open={true}
              anchorEl={anchorEl}
              block={element}
              onShowProperties={handleShowProperties}
              onComments={handleShowComments}
              onClose={handleCloseMenu}
            />
          ) : null}
        </>
      )}
      {blockDecorations.length > 0 ? (
        <Box
          contentEditable={false}
          tabIndex={-1}
          sx={{
            position: 'absolute',
            right: 0,
            userSelect: 'none',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            mr: 1,
          }}
        >
          {element.options?.activeView ? (
            <Typography tabIndex={-1} variant="caption" color={'primary.main'} contentEditable={false}>
              View: {element.options?.activeView}
            </Typography>
          ) : null}
          {blockDecorations}
        </Box>
      ) : null}
      {/* {sketches ? <SketchBlock element={element}>{children}</SketchBlock> : <>{children}</>} */}
      {/* 
      In Chrome the caret jumps to the end of the contenteditable element when it is the last child of a parent element.
      This is not an issue in Firefox or Safari.
    
       @see https://stackoverflow.com/questions/27786048/why-is-my-contenteditable-caret-jumping-to-the-end-in-chrome
      */}
      <>{children}</>
    </Box>
  )
}
