/** Allow renderLeaf to render content from different plugins */

import { styled } from '@mui/material/styles'
import type { TText } from '@tunasong/plugin-lib'
import { useEditor } from '@tunasong/plugin-lib'

import React, { useMemo } from 'react'

interface LeafProps {
  bold?: boolean
  italic?: boolean
  strikethrough?: boolean
  underline?: boolean
  highlight?: boolean
}

const LeafSpan = styled('span', {
  shouldForwardProp: prop => !['bold', 'italic', 'strikethrough', 'underline', 'highlight'].includes(String(prop)),
})<LeafProps>(({ theme, bold, italic, strikethrough, underline, highlight }) => ({
  position: 'relative',
  ...(bold && { fontWeight: 'bold' }),
  ...(italic && { fontStyle: 'italic' }),
  ...(strikethrough && { textDecoration: 'line-through' }),
  ...(underline && { textDecoration: 'underline' }),
  ...(highlight && { backgroundColor: theme.vars.palette.warning.light }),
}))

interface RenderLeafProps {
  leaf: TText
  attributes: Record<string, unknown>
  children: React.ReactNode
}

export const RenderLeaf = (props: RenderLeafProps) => {
  const { leaf, attributes, children } = props

  /** Get the plugins  */
  const editor = useEditor()
  const { pluginList: plugins } = editor

  const leafStyle = useMemo(() => {
    const pluginStyles = plugins.map(p => p.getLeafStyle && p.getLeafStyle(leaf)).filter(Boolean)
    let styles: React.CSSProperties = {}
    for (const style of pluginStyles) {
      styles = { ...styles, ...style }
    }
    return styles
  }, [leaf, plugins])

  return (
    <LeafSpan
      {...attributes}
      style={leafStyle}
      bold={leaf.bold as boolean}
      italic={leaf.italic as boolean}
      strikethrough={leaf.strikethrough as boolean}
      underline={leaf.underline as boolean}
      highlight={leaf.highlight as boolean}
    >
      {plugins.map(p => (p.RenderLeaf ? <p.RenderLeaf key={p.key} leaf={leaf} /> : null))}
      {children}
    </LeafSpan>
  )
}

export default RenderLeaf
