import type { PopperProps } from '@mui/material'
import { ClickAwayListener, Fade, Paper, Popper } from '@mui/material'
import { logger } from '@tunasong/models'
import type { TunaEditor } from '@tunasong/plugin-lib'
import { FocusLock } from '@tunasong/ui-lib'
import type { FC } from 'react'
import React, { useCallback } from 'react'
import { ReactEditor } from 'slate-react'
import { getCurrentHTMLElement } from '../util/current-html-element.js'

export interface EditorPopupProps extends PopperProps {
  className?: string
  editor: TunaEditor
  focusLock?: boolean
  autoFocus?: boolean
  hideOnBackspace?: boolean
  /** Paper elevation */
  elevation?: number
  anchorEl?: PopperProps['anchorEl'] | null
  children?: React.ReactNode
  onClose(): void
}

/** @todo refactor to use Popup component instead here */

export const EditorPopup: FC<EditorPopupProps> = props => {
  const {
    placement = 'right-start',
    focusLock = true,
    autoFocus = true,
    disablePortal = true,
    hideOnBackspace = false,
    open,
    editor,
    elevation = 1,
    anchorEl,
    children,
    onClose,
    ...restProps
  } = props

  const anchor = anchorEl ? anchorEl : getCurrentHTMLElement(editor)

  const handleFocus = () => {
    /** @see https://github.com/theKashey/react-focus-lock (Unmounting and focus management) */
    setTimeout(() => {
      try {
        // if we're navigating away from this page, we may get into trouble here, so we catch the error
        ReactEditor.focus(editor as unknown as ReactEditor)
        // this is required to ensure we don't lose the selection
        editor.api.onChange()
      } catch (e) {
        logger.warn(`Focus editor failed`, { e })
      }
    }, 0)
  }

  const handleClose = useCallback(() => onClose(), [onClose])

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (ev.key === 'Escape' || (hideOnBackspace && ev.key === 'Backspace')) {
        ev.preventDefault()
        handleClose()
      }
    },
    [handleClose, hideOnBackspace]
  )

  if (!open) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
      <Popper
        sx={{
          zIndex: 1300,
        }}
        contentEditable={false}
        open={true}
        transition={true}
        disablePortal={disablePortal}
        keepMounted={false}
        placement={placement}
        onKeyDown={handleKeyDown}
        anchorEl={anchor}
        // This is needed to be able render inside of dialogs when using disablePortal={true}
        popperOptions={{
          ...props.popperOptions,
          placement,
          strategy: 'fixed',
        }}
        {...restProps}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper elevation={elevation}>
              {/* returnFocus will reset selection to [0,0] and we don't want that */}
              <FocusLock disabled={!focusLock} returnFocus={false} onDeactivation={handleFocus} autoFocus={autoFocus}>
                {children}
              </FocusLock>
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  )
}

export default EditorPopup
