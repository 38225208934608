import type { TunaEditor } from '@tunasong/plugin-lib'
import { Node, Path } from 'slate'
import { ReactEditor } from 'slate-react'
import { isReactEditor } from '../editor/tuna-editor.js'

export const getHTMLElementAtPath = (editor: TunaEditor, path?: Path) => {
  let currentHTMLElement: HTMLElement | null = null
  try {
    currentHTMLElement =
      path && isReactEditor(editor)
        ? ReactEditor.toDOMNode(editor as unknown as ReactEditor, Node.get(editor, path))
        : null
  } catch {}
  return currentHTMLElement
}
